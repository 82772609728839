$(function(){

  const
    $window = $(window),
    $headerContainer = $(".l-headerContainer"),
    $header = $(".l-header"),
    $topMessage = $(".l-topMessage"),
    $drawerButton = $(".js-toggleDrawer"),
    $overlay = $(".js-drawerOverlay"),
    $topMessageCloseButton = $(".js-closeTopMessage"),
    topMessageHidePos = 100;

  let 
    topMessageHeight = $topMessage.outerHeight(),
    headerHeight = $header.outerHeight(),
    isDrawerShow = false,
    isTopMessageShow = true,
    isDuringAnimationTopMessage = false,
    crtTopMessageHeight = topMessageHeight,
    userClosedMessage = false,  // ユーザーが閉じる操作をしたかどうか
    resizeTimer,
    lastWindowWidth = window.innerWidth;

  // CSSプロパティをセットする関数
  const setCssProperties = () => {
    const crtTopMessageHeight = $topMessage.outerHeight();
    const crtHeaderHeight = $header.outerHeight()

    document.documentElement.style.setProperty('--top-message-height', `${crtTopMessageHeight}px`);
    document.documentElement.style.setProperty('--header-height', `${crtHeaderHeight}px`);
  };

  // ドロワーを開く
  const showDrawer = function(){
    $headerContainer.addClass('is-show');
    isDrawerShow = true;
  };

  // ドロワーを閉じる
  const hideDrawer = function(){
    $headerContainer.removeClass('is-show');
    isDrawerShow = false;
  };

  // メッセージを閉じるボタン
  $topMessageCloseButton.on("click", function(){
    userClosedMessage = true;
    hideTopMessage();
  });

  // ドロワートグルボタン
  $drawerButton.on("click", function(){
    if (isDrawerShow) {
      hideDrawer();
    } else {
      showDrawer();
    }
  });

  // オーバーレイ
  $overlay.on("click", function(){
    hideDrawer();
  });

  if (!topMessageHeight) {
    topMessageHeight = 0;
  }
  document.documentElement.style.setProperty('--top-message-height', `${topMessageHeight}px`);
  document.documentElement.style.setProperty('--header-height', `${headerHeight}px`);

  const showTopMessage = function(){
    // すでに表示中ならreturn
    // ユーザーが閉じる操作をしたなら以降は開かない
    if (isTopMessageShow || userClosedMessage) {
      return;
    }

    // 表示中ステータス
    isTopMessageShow = true;
    // animation中ステータス
    isDuringAnimationTopMessage = true;
    // 現在のメッセージエリアの高さを、本来の高さに設定
    crtTopMessageHeight = topMessageHeight;
    // css変数をset
    document.documentElement.style.setProperty('--top-message-height', `${crtTopMessageHeight}px`);

    $topMessage.slideDown().queue(function() {
      // animation中ステータス 解除
      isDuringAnimationTopMessage = false;
      $(this).dequeue();
    })
    
  };

  const hideTopMessage = function(){
    // すでに非表示中ならreturn
    if (!isTopMessageShow) {
      return;
    }

    // 非表示中ステータス
    isTopMessageShow = false;
    // animation中ステータス
    isDuringAnimationTopMessage = true;
    // 現在のメッセージエリアの高さを、0に設定
    crtTopMessageHeight = 0;
    // css変数をset
    document.documentElement.style.setProperty('--top-message-height', `${crtTopMessageHeight}px`);
    
    $topMessage.slideUp().queue(function() {
      // animation中ステータス 解除
      isDuringAnimationTopMessage = false;
      $(this).dequeue();
    });

  };

  $window.on("scroll", function() {
    // アニメーション途中ならreturn
    if (isDuringAnimationTopMessage) {
      return;
    }

    const currentPos = $window.scrollTop();

    // 設定値よりスクロール量が多かったらhide
    if (currentPos-crtTopMessageHeight > topMessageHidePos) {
      hideTopMessage();
      return;
    }

    // 設定値よりスクロール量が少なかったらshow
    showTopMessage();
  });

  window.addEventListener('resize', () => {
    // resizeTimerがセットされていればクリアする
    if (resizeTimer) {
      clearTimeout(resizeTimer);
    }
  
    // 100ms待ってからsetCssPropertiesを実行する
    resizeTimer = setTimeout(() => {
      // 横幅が変わっているかチェックする
      if (lastWindowWidth !== window.innerWidth) {
        lastWindowWidth = window.innerWidth; // 現在の横幅を更新する
        setCssProperties(); // CSSプロパティをセットする関数を実行する
      }
    }, 100);
  });

});
