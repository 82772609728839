$(function(){

  const $trigger = $(".js-toggleSubGlobalMenu");

  $trigger.on("click", function(){
    const $subGlobalMenu = $(this).next();
    $subGlobalMenu.slideToggle(500, 'easeInOutQuart');
    $(this).toggleClass("is-show");
  });

});