(function () {
  class ScrollFade {
    constructor() {
      this.ANIMATION_CLASS = 'is-animation';

      let $section = document.querySelectorAll('.js-scroll-magic:not(.is-animation)');
      if ($section.length === null) {
        return;
      }
      let controller = new ScrollMagic.Controller();
      for (let i = 0, len = $section.length; i < len; i=(i+1)|0) {
        let scene = new ScrollMagic.Scene({
            triggerElement: $section[i],
            triggerHook: 'onEnter',
            reverse: false,
            offset: 100
          })
          .addTo(controller);
        scene.on('enter', () => {
          $section[i].classList.add(this.ANIMATION_CLASS);
        });
        scene.on('end', () => {
          scene.destroy(true);
        });
      }
    }
  }

  $(function() {
    new ScrollFade();
  });
})();