/* ============================================================
namespace
============================================================ */

window.ith = window.ith||{};
window.ith.function = window.ith.function||{};
window.ith.state = window.ith.state||{};

/* ====================  ==================== */

window.ith.state.breakPoint = 900;
window.ith.state.screen = "narrow";

/* ============================================================
スクリーンサイズチェック
============================================================ */

$(window).on("load resize", function() {
  let wW = $(window).innerWidth();
  if (wW > window.ith.state.breakPoint) {
    if (window.ith.state.screen === "wide") { return; }
    window.ith.state.screen = "wide";
  } else {
    if (window.ith.state.screen === "narrow") { return; }
    window.ith.state.screen = "narrow";
  }
});

(function(){

  const
    $target = $('a[href^="#"],a[href^="/#"]'),
    urlHash = location.hash,
    scrollTime = 1000,
    scrollEasing = "easeInOutQuart",
    scrollOffsetYWide = -70,
    scrollOffsetYNarrow = -55;

  function scrollToAnker(hash) {
    const
      target = $(hash),
      position = target.offset().top;
    let offset = scrollOffsetYNarrow;

    if (window.ith.state.screen === "wide") {
      offset = scrollOffsetYWide;
    }

    $("html,body").stop().animate({scrollTop: position + offset }, scrollTime, scrollEasing);
  }

  if (urlHash) {
    if ($(urlHash).length) {
      $(function() {
        $("html,body").stop().animate({ scrollTop: 0 }, 0);
      });
      $(window).on("load", function(){
        setTimeout(function() {
          scrollToAnker(urlHash);
        }, 10);
      });
    }
  }

  $target.on("click", function(){
    const
      id = $(this).attr("href").split("#"),
      hash = id[id.length-1];
    scrollToAnker("#" + hash);
  });

})();