$(function(){
  $(function() {
    const postsSwiper = new Swiper(".js-postsSwiper", {
      slidesPerView: "auto",
      spaceBetween: 3,
      breakpoints: {
        900: {
          spaceBetween: 32,
        }
      }
    });
  });
});

$(function(){
  $(function() {
    const postsSwiper2 = new Swiper(".js-postsSwiper2", {
      slidesPerView: "auto",
      spaceBetween: 10,
      breakpoints: {
        900: {
          spaceBetween: 20,
        }
      }
    });
  });
});
