(function(){
  // img要素
  const $lazy = document.querySelectorAll('.js-lazyload');

  window.addEventListener('DOMContentLoaded', () => {
    inViewport($lazy);
  });

  // 要素のsrc, srcsetをセット
  function setImgSrc(element){

    // data-src属性があればsrc属性にセット
    if(element.dataset.src){
      element.src = element.dataset.src;
    }
    
    // data-srcset属性があればsrc属性にセット
    if(element.dataset.srcset){
      element.srcset = element.dataset.srcset;
    }

  }

  function inViewport(entries){
    entries.forEach(entry => {
      const imgEl = entry;
      const parent = imgEl.parentElement;

      // 親がpicture要素なら、picture要素内のすべての要素(source, img)を対象にする
      if (parent.tagName === "PICTURE") {
        const children = parent.children;
        for (let i = 0, len = children.length; i < len; i=(i+1)|0) {
          setImgSrc(children[i]);
        }
      } else {
        // 親がpicture要素ではないならそのimg要素のみ
        setImgSrc(imgEl);
      }

      // 画像のloadが終わったら、表示用のclass追加し、widthとheightを削除
      imgEl.addEventListener('load', ()=>{
        imgEl.classList.add('is-lazyloaded');
        imgEl.removeAttribute("width");
        imgEl.removeAttribute("height");
      });

    });
  }
})();